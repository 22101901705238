var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Entry',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Bootstrap custom "),_c('code',[_vm._v("<select>")]),_vm._v(" using custom styles. Optionally specify options based on an array, array of objects, or an object. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('manually')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('first')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('changing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('single-mode')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sizing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('multiple')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }